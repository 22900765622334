export default [
  {
    header:'Back Office'
  },
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    privilege: 1,
    // navActiveLink:'home',
  },
  {
    title: 'Master & Setting',
    icon: 'SettingsIcon',
    privilege: 2,
    children: [
      {
        title: 'User',
        route: 'users',
        privilege: 3
      },
      {
        title: 'Format Penomoran',
        route: 'numberings',
        privilege: 14
      },
      {
        title: 'Pemasok',
        route: 'suppliers',
        privilege: 18,
      },
      {
        title: 'Mitra Pembayaran',
        route: 'partners',
        privilege: 22,
      },
      {
        title: 'Spesifikasi Produk',
        route: 'productspecs',
        privilege: 26,
      },
      {
        title: 'Kategori Produk',
        route: 'itemcategories',
        privilege: 39,
      },
      {
        title: 'Produk',
        route: 'items',
        privilege: 43,
      },
    ]
  },
  {
    title: 'Penerimaan',
    icon: 'DownloadIcon',
    route: 'receptions',
    privilege: 48,
  },
  {
    title: 'Retur Pembelian',
    icon: 'CornerUpLeftIcon',
    route: 'purchasereturns',
    privilege: 52
  },
  {
    title: 'Stok Opname',
    icon: 'ColumnsIcon',
    route: 'stockopnames',
    privilege: 56
  },
  {
    title: 'Laporan',
    icon: 'FileIcon',
    privilege: 60,
    children: [
      {
        title: 'Penjualan',
        route: 'reportsale',
        privilege: 61
      },
      {
        title: 'Posisi Stok',
        route: 'stockpos',
        privilege: 62
      },
      {
        title: 'Kartu Stok',
        route: 'stockcard',
        privilege: 63
      },
    ]
  },
  {
    header:'POS'
  },
  {
    title: 'Penjualan Baru',
    icon: 'ShoppingBagIcon',
    route: 'sales',
    privilege: 66
  },
  {
    title: 'Penukaran Barang',
    icon: 'RepeatIcon',
    route: 'returns',
    privilege: 70
  },
  {
    title: 'Daftar Transaksi',
    icon: 'CircleIcon',
    route: 'saletransactions',
    privilege: 74
  },
  {
    title: 'Laporan Penjualan',
    icon: 'FileIcon',
    route: 'cashiersaletransactions',
    privilege: 75
  },
]
